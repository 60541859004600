import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ImgixImage from '../ImgixImage';

import useMobile from '../useMobile';

const GalleryImage = ({
  src,
  large,
  pinterestDescription,
  square,
  rect,
  lazy,
  imageOptions = {},
  ...rest
}) => {
  const isMobile = useMobile();
  const baseOpts = {
    rect: (rect || []).join(','),
    fit: 'crop',
    auto: ['compress', 'format'],
    q: isMobile ? 60 : 80,
    ...imageOptions,
  };
  const imgOpts = square
    ? { ...baseOpts, ar: '1:1', crop: 'entropy' }
    : baseOpts;

  return (
    <ImgixImage
      options={imgOpts}
      srcSetOptions={imgOpts}
      src={src}
      srcSetRanges={{ minWidth: 200, maxWidth: 3000 }}
      sizes={large ? '40vw' : '20vw'}
      lazy={lazy}
      data-pinterest-description={pinterestDescription}
      {...rest}
    />
  );
};

GalleryImage.propTypes = {
  src: PropTypes.string.isRequired,
  large: PropTypes.object,
  square: PropTypes.bool,
  pinterestDescription: PropTypes.string,
  rect: PropTypes.array,
  alt: PropTypes.string,
  lazy: PropTypes.bool,
  imageOptions: PropTypes.object,
};

export default memo(GalleryImage);
