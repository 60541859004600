import React, { useContext, memo } from 'react';
import PropTypes from 'prop-types';
import { Box, ResponsiveContext } from 'grommet';
import styled from 'styled-components';

import Modal from '../Modal';
import FormGalleryFilter from './FormGalleryFilter';

const Container = styled(Box)`
  align-items: flex-start;
  align-self: flex-start;
  overflow: auto;
  width: 100%;

  @media only screen ${(p) =>
      p.theme.global.breakpoints.small.value &&
      `and (max-width: ${p.theme.global.breakpoints.small.value}px)`} {
    flex-basis: calc(100% * (4 / 6));
    max-width: calc(100% * (4 / 6));
    padding: 27px 24px;
    max-height: 100vh;
    height: 100%;
  }
`;

const GalleryFilterPanel = (props) => {
  const size = useContext(ResponsiveContext);
  const isMobile = size === 'small';

  return (
    <>
      {isMobile ? (
        <>
          {props.isActive && (
            <Modal
              onClose={props.onFilterToggle}
              position="top"
              full="horizontal"
              margin={{ top: 'none', horizontal: 'medium' }}
              maxHeight="60vh"
              border={[
                { side: 'bottom', size: 'small' },
                { side: 'vertical', size: 'small' },
              ]}
            >
              <Box pad="medium" fill overflow="auto">
                <FormGalleryFilter {...props} />
              </Box>
            </Modal>
          )}
        </>
      ) : (
        <Container flex={{ grow: 1, shrink: 0 }} height="100%">
          <Box
            overflow="auto"
            pad={{ horizontal: 'small', vertical: 'medium' }}
            fill="horizontal"
            align="start"
            justify="center"
          >
            <FormGalleryFilter {...props} />
          </Box>
        </Container>
      )}
    </>
  );
};

GalleryFilterPanel.propTypes = {
  isActive: PropTypes.bool,
  onFilterToggle: PropTypes.func,
};

export default memo(GalleryFilterPanel);
