import React, { useCallback, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';
import InfiniteLoader from 'react-window-infinite-loader';
import { compact } from '../../lib/nodash';

import { useWindowSize } from '../useWindowSize';
import UgcWindowedList from './UgcWindowedList';
import UgcGalleryEmptyState from './UgcGalleryEmptyState';
import chunkArray from '../../lib/chunkArray';
import useMobile from '../useMobile';

const UgcGallery = ({
  items = [],
  uniform,
  totalCount,
  placementsLoading,
  onLoadMore,
  onItemClick,
  onResetFilters,
  activeFilters = [],
  onWindowScroll,
  featuredFilters = [],
  initialLoaded = false,
  heroConfig = {},
  galleryViewportHeight,
  colors,
}) => {
  const [windowWidth] = useWindowSize();
  const isMobile = useMobile();

  const mult = isMobile ? 1.3 : 1; // Height scaler
  const LARGE_RATIO = 0.4986 * mult;
  const DEFAULT_RATIO = 0.265 * mult;

  const trios = useMemo(() => chunkArray(compact(items), 3), [items]);

  const isItemLoaded = (index) => index < trios.length;

  const loadMoreItems = useCallback(() => {
    if (onLoadMore) {
      onLoadMore();
    }
  }, [onLoadMore]);
  const moduleCount = totalCount / 9;
  const largeGroupCount = moduleCount; // one large per module
  const defaultGroupCount = moduleCount * 2; // two default per module
  const listHeight = useMemo(
    () =>
      largeGroupCount * (windowWidth * LARGE_RATIO + 2) +
      defaultGroupCount * (windowWidth * DEFAULT_RATIO + 2),
    [windowWidth, largeGroupCount, defaultGroupCount]
  );

  return (
    <Box direction="column" fill className="ugc-gallery-container">
      <Box fill>
        {!placementsLoading && items.length === 0 ? (
          <UgcGalleryEmptyState
            activeFilters={activeFilters}
            featuredFilters={featuredFilters}
            onResetFilters={onResetFilters}
            colors={colors}
          />
        ) : (
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={totalCount === 0 ? 1000 : totalCount}
            loadMoreItems={loadMoreItems}
            threshold={isMobile ? 35 : 15}
          >
            {({ onItemsRendered, ref }) => {
              return (
                <UgcWindowedList
                  className="windowed-list"
                  windowHeight={galleryViewportHeight}
                  windowWidth={windowWidth}
                  listHeight={listHeight}
                  onItemsRendered={onItemsRendered}
                  ratios={{
                    large: LARGE_RATIO,
                    default: DEFAULT_RATIO,
                  }}
                  onItemClick={onItemClick}
                  ref={ref}
                  trios={trios}
                  uniform={uniform}
                  activeFilters={activeFilters}
                  onScroll={onWindowScroll}
                  initialLoaded={initialLoaded}
                  heroConfig={heroConfig}
                  heightMult={mult}
                />
              );
            }}
          </InfiniteLoader>
        )}
      </Box>
    </Box>
  );
};

UgcGallery.propTypes = {
  items: PropTypes.array.isRequired,
  uniform: PropTypes.bool,
  totalCount: PropTypes.number,
  placementsLoading: PropTypes.bool,
  onWindowScroll: PropTypes.func,
  onLoadMore: PropTypes.func,
  onResetFilters: PropTypes.func,
  onItemClick: PropTypes.func,
  activeFilters: PropTypes.array,
  colors: PropTypes.array,
  featuredFilters: PropTypes.array,
  initialLoaded: PropTypes.bool,
  heroConfig: PropTypes.object,
  galleryViewportHeight: PropTypes.number,
};

export default memo(UgcGallery);
