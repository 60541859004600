import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';

import ProductTag from './ProductTag';
import { shopifyClient } from '../../gatsby-theme-apollo/client';
import { isPaint } from '../../lib/product';
import { COLLECTION_BY_HANDLE_BASIC } from '../../queries/collectionQueries';

const PaintCollectionOrProductTag = ({ product, large, tagSize }) => {
  if (!product) {
    return null;
  }
  const isPaintProduct = useMemo(() => isPaint(product));

  const { data } = useQuery(COLLECTION_BY_HANDLE_BASIC, {
    client: shopifyClient,
    variables: {
      handle: product.handle
        .replace('-interior-standard', '')
        .replace('-interior-semi-gloss', '')
        .replace('-exterior-standard', '')
        .replace('-exterior-semi-gloss', '')
        .replace('-cabinet-and-door', ''),
    },
    skip: !isPaintProduct,
  });

  const taggedProduct = isPaintProduct ? data?.collection : product;

  return <ProductTag product={taggedProduct} large={large} tagSize={tagSize} />;
};

PaintCollectionOrProductTag.propTypes = {
  product: PropTypes.object,
  large: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  tagSize: PropTypes.string,
};

export default memo(PaintCollectionOrProductTag);
