import React, {
  useEffect,
  useCallback,
  useState,
  memo,
  createRef,
  useLayoutEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Box } from 'grommet';
import { VariableSizeList as List, areEqual } from 'react-window';
import composeRefs from '@seznam/compose-react-refs';

import UgcGalleryGroup from './UgcGalleryGroup';
import { setGalleryListRef } from '../../state/ui/uiSlice';
import { isEqual } from '../../lib/nodash';

const RowInner = memo(({ data, index, style }) => {
  const { trios, uniform, onItemClick, heightMult } = data;
  const isHeroLayout = uniform ? false : (index - 1) % 3 === 0;
  const heroIndex = uniform ? -1 : 0;

  return (
    <div style={style}>
      {index === 0 ? (
        <Box height={`${data.heroConfig.height}px`}>
          {data.heroConfig.content}
        </Box>
      ) : (
        <UgcGalleryGroup
          heightMult={heightMult}
          index={index - 1}
          items={trios[index - 1]}
          isHeroLayout={isHeroLayout}
          heroIndex={heroIndex}
          uniform={uniform}
          handleItemClick={onItemClick}
        />
      )}
    </div>
  );
});

RowInner.displayName = 'RowInner';

RowInner.propTypes = {
  index: PropTypes.number,
  style: PropTypes.object,
  data: PropTypes.object,
};

const Row = memo(RowInner, areEqual);

const UgcWindowedList = React.forwardRef(
  (
    {
      windowHeight,
      windowWidth,
      trios,
      onItemsRendered,
      uniform,
      ratios,
      onItemClick,
      activeFilters = [],
      onScroll,
      initialLoaded,
      heroConfig,
      heightMult,
    },
    ref
  ) => {
    const listRef = createRef();
    const dispatch = useDispatch();
    const [filters, setFilters] = useState(activeFilters);
    useEffect(() => {
      if (listRef.current) {
        listRef.current.resetAfterIndex(0);
      }
    }, [windowWidth, uniform, trios]);

    useEffect(() => {
      if (initialLoaded && !isEqual(activeFilters, filters)) {
        setFilters(activeFilters);
        listRef.current.scrollTo(heroConfig.height);
      }
    }, [activeFilters, filters, heroConfig, listRef]);

    const getSize = useCallback(
      (index) => {
        if (index === 0) {
          return heroConfig.height;
        }
        const isHeroLayout = (index - 1) % 3 === 0;
        const size = uniform
          ? windowWidth * ratios.default + 3
          : isHeroLayout
          ? windowWidth * ratios.large
          : windowWidth * ratios.default;
        return size;
      },
      [uniform, ratios, windowWidth]
    );

    const containerClass = 'ugc-gallery-scroller';

    useLayoutEffect(() => {
      if (listRef.current) {
        dispatch(setGalleryListRef(`.${containerClass}`));
      }
    }, [listRef]);

    return (
      <List
        height={windowHeight}
        width="100%"
        itemCount={trios.length + 1}
        itemData={{ trios, uniform, onItemClick, heroConfig, heightMult }}
        itemSize={getSize}
        estimatedItemSize={windowWidth * ratios.default}
        onItemsRendered={onItemsRendered}
        overscanCount={12}
        className={containerClass}
        ref={composeRefs(listRef, ref)}
        onScroll={(e) => {
          if (onScroll) {
            onScroll(e);
          }
        }}
      >
        {Row}
      </List>
    );
  }
);

UgcWindowedList.displayName = 'UgcWindowedList';

UgcWindowedList.propTypes = {
  windowHeight: PropTypes.number,
  windowWidth: PropTypes.number,
  trios: PropTypes.array,
  onItemsRendered: PropTypes.func,
  uniform: PropTypes.bool,
  ratios: PropTypes.object,
  onItemClick: PropTypes.func,
  listHeight: PropTypes.number,
  activeFilters: PropTypes.array,
  onScroll: PropTypes.func,
  initialLoaded: PropTypes.bool,
  heroConfig: PropTypes.object,
  heightMult: PropTypes.number,
};

export default UgcWindowedList;
