import React, { Fragment, useContext, memo } from 'react';
import PropTypes from 'prop-types';
import { Heading, ResponsiveContext } from 'grommet';
import { get } from '../../lib/nodash';
import styled from 'styled-components';
import he from 'he';

import deNodify from '../../lib/deNodify';
import TagOverlay from '../TagOverlay';
import { findImageMetafield } from '../../lib/product';
import findImageForProduct from '../../lib/findImageForProduct';

const OverlayHeading = styled(Heading)`
  font-size: 5vw;
  line-height: 5vw;
  @media only screen ${(p) =>
      p.theme.global.breakpoints.small.value &&
      `and (max-width: ${p.theme.global.breakpoints.small.value}px)`} {
    font-size: 6vw;
    line-height: 7.4vw;
  }
`;

const ProductTag = ({ product, large, tagSize }) => {
  const size = useContext(ResponsiveContext);

  if (!product) {
    return <Fragment />;
  }
  const image = product.collection_image
    ? JSON.parse(product.collection_image.value)[0]?.original_src
    : product.metafields
    ? findImageMetafield('collection_image', product.metafields)
    : findImageForProduct(
        deNodify(product.media || product.images),
        product.productType
      );

  return (
    <Fragment>
      {large ? (
        <OverlayHeading
          textAlign="center"
          color="white"
          level={size === 'small' ? 2 : 1}
        >
          {he.decode(get('title', product))}
        </OverlayHeading>
      ) : (
        <>
          {size !== 'small' && (
            <TagOverlay
              size={tagSize}
              text={he.decode(get('title', product))}
              imageUrl={
                Array.isArray(image)
                  ? image[0].original_src
                  : get('original_src', image) || image
              }
            />
          )}
        </>
      )}
    </Fragment>
  );
};

ProductTag.propTypes = {
  product: PropTypes.object,
  large: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  tagSize: PropTypes.string,
};

export default memo(ProductTag);
