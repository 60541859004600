import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Box } from 'grommet';
import styled from 'styled-components';
import { get, head, take, filter } from '../../lib/nodash';

import { shopifyClient } from '../../gatsby-theme-apollo/client';
import { GET_PRODUCTS_BY_IDS } from '../../queries/productQueries';
import PaintCollectionOrProductTag from '../Product/PaintCollectionOrProductTag';
import GalleryImage from './GalleryImage';
import { isPaintType, isApparelType } from '../../lib/productTypes';
import { getProductPath, isWallcovering } from '../../lib/product';
import { generateAltText } from '../../lib/ugc';

const ImageContainer = styled(Box)`
  position: relative;
  ${(p) =>
    p.isLarge && 'display: flex; flex-direction: row; justify-content: center;'}
  ${(p) => p.uniform && 'height: 26.5vw;'}
  ${(p) =>
    p.inLargeGroup &&
    `
    height: 30%;
  `}
  > img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    flex: 1 1;
  }
`;

const ProductTags = styled(Box)`
  position: absolute;
  top: ${(p) => (p.large ? 'auto' : 0)};
  right: ${(p) => (p.large ? 'auto' : 0)};
`;

const UgcGalleryItem = ({
  id,
  ugc,
  inLargeGroup,
  large,
  rect = [],
  uniform,
  handleClick,
  tagSize = 'medium',
  basis = '1/3',
  linkToProduct = false,
  imageOptions,
  lazy,
}) => {
  const hasRelatedProducts = (get('related_products', ugc) || []).length > 0;
  const { file } = ugc;
  const hasCrop = Array.isArray(rect) && rect.length > 0;
  const square = !hasCrop && !inLargeGroup && !large;

  const { data } = useQuery(GET_PRODUCTS_BY_IDS, {
    client: shopifyClient,
    variables: { productIds: get('related_products', ugc) },
    skip:
      !get('related_products', ugc) ||
      get('related_products', ugc).length === 0,
  });

  const products = get('nodes', data) || [];

  const altText = useMemo(
    () => generateAltText(ugc, products),
    [ugc, products]
  );

  const productUrl = getProductPath(head(products));

  const featuredProducts = useMemo(() => {
    return take(
      2,
      filter((x) => {
        return (
          isPaintType(get('group_type', x)) ||
          isPaintType(get('productType', x)) ||
          isWallcovering(x) ||
          isApparelType(get('productType', x))
        );
      }, products)
    );
  }, [products]);

  if (!ugc) {
    return null;
  }

  return (
    <ImageContainer
      inLargeGroup={inLargeGroup}
      isLarge={large}
      uniform={uniform}
      flex={{ grow: uniform ? 0 : 1, shrink: 1 }}
      basis={basis}
      overflow="hidden"
      className={`uniform-${uniform}`}
      align="center"
      background="light-4"
      href={linkToProduct && productUrl ? productUrl : null}
      onClick={hasRelatedProducts ? () => handleClick(id, products) : null}
    >
      <GalleryImage
        src={file.url}
        large={large}
        pinterestDescription={altText}
        square={square}
        rect={rect}
        alt={altText}
        imageOptions={imageOptions}
        lazy={lazy}
      />
      <ProductTags
        pad="small"
        justify={large ? 'center' : 'end'}
        align="end"
        large={large}
        gap="xsmall"
      >
        {(large ? [head(featuredProducts)] : featuredProducts || []).map(
          (x) => (
            <PaintCollectionOrProductTag
              key={`${id}-product-${get('id', x)}`}
              product={x}
              large={large}
              tagSize={tagSize}
            />
          )
        )}
      </ProductTags>
    </ImageContainer>
  );
};

UgcGalleryItem.propTypes = {
  ugc: PropTypes.object,
  inLargeGroup: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  large: PropTypes.object,
  rect: PropTypes.array,
  uniform: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  handleClick: PropTypes.func,
  basis: PropTypes.string,
  tagSize: PropTypes.string,
  linkToProduct: PropTypes.bool,
  imageOptions: PropTypes.object,
  lazy: PropTypes.bool,
};

export default memo(UgcGalleryItem);
