import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';
import { map, reject } from '../../lib/nodash';
import styled from 'styled-components';
import UgcGalleryItem from './UgcGalleryItem';

const LargeGroupContainer = styled(Box)`
  flex-direction: ${(p) => (p.reverse ? 'row-reverse' : 'row')};
  height: ${(p) => 49.86 * p.heightMult}vw;
`;

const LargeItemContainer = styled(Box)`
  width: ${(p) => p.percentageWidth}%;
`;

const UgcGalleryGroup = ({
  items = [],
  uniform,
  index,
  handleItemClick,
  heroIndex,
  isHeroLayout,
  heightMult = 1,
}) => {
  const heroItem = items[heroIndex];
  const alternateHero = index % 6 !== 0;

  return (
    <Box
      direction="row"
      className="ugc-group-container"
      fill="horizontal"
      gap="xxsmall"
    >
      {!uniform && isHeroLayout && heroItem ? (
        <LargeGroupContainer
          direction="row"
          fill="horizontal"
          className={`${heroItem ? 'large' : 'medium'}-group`}
          gap="xxsmall"
          pad={{ top: 'xxsmall' }}
          heightMult={heightMult}
        >
          <Box
            gap="xxsmall"
            direction={alternateHero ? 'row-reverse' : 'row'}
            fill="horizontal"
          >
            <LargeItemContainer
              fill="vertical"
              className="large-ugc-items"
              basis="auto"
              percentageWidth={60}
            >
              <UgcGalleryItem
                {...heroItem}
                key={heroItem.id}
                height="100%"
                gap="xxsmall"
                large={heroItem}
                handleClick={handleItemClick}
              />
            </LargeItemContainer>
            <Box
              flex="grow"
              className="small-ugc-items"
              gap="xxsmall"
              width="40%"
            >
              {map(
                (x) => (
                  <UgcGalleryItem
                    {...x}
                    key={x.id}
                    inLargeGroup
                    handleClick={handleItemClick}
                  />
                ),
                reject({ id: heroItem.id }, items)
              )}
            </Box>
          </Box>
        </LargeGroupContainer>
      ) : (
        <Box
          direction="row"
          gap="xxsmall"
          fill="horizontal"
          justify="start"
          alignContent="start"
          height={`${26.5 * heightMult}vw`}
          pad={{ top: 'xxsmall' }}
        >
          {items.map((x) => (
            <UgcGalleryItem
              {...x}
              key={x.id}
              uniform={uniform}
              handleClick={handleItemClick}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

UgcGalleryGroup.propTypes = {
  isHeroLayout: PropTypes.bool,
  index: PropTypes.number,
  heroIndex: PropTypes.number,
  alternateHero: PropTypes.bool,
  items: PropTypes.array,
  uniform: PropTypes.bool,
  handleItemClick: PropTypes.func,
  heightMult: PropTypes.number,
};

export default memo(UgcGalleryGroup);
