import React from 'react';
import PropTypes from 'prop-types';
import { Box, Heading, Button, Paragraph } from 'grommet';
import {
  reduce,
  get,
  uniq,
  sortBy,
  reject,
  map,
  includes,
  isEqual,
  startCase,
  find,
} from '../../lib/nodash';

const UgcGalleryEmptyState = ({
  activeFilters,
  featuredFilters,
  onResetFilters,
  colors,
}) => {
  const isColorTag = (tag) => includes('color:', tag.slug);
  const isColor = (tag) => find({ slug: tag }, colors);
  const handleSetFilter = (filter) => {
    onResetFilters(
      isColorTag(filter)
        ? {
            colorTags: [filter],
          }
        : isColor(filter)
        ? { colors: [filter] }
        : { ugcTags: [filter] }
    );
  };
  const suggestions = sortBy(
    (x) => (includes('color:', x.slug) ? 0 : 1),
    uniq(
      reduce(
        (mem, x) => {
          const suggestion = isColorTag(x)
            ? [x]
            : isColor(x)
            ? reject((y) => {
                return (
                  includes(get('slug', y), map('slug', featuredFilters)) ||
                  isEqual('color:our-picks', y)
                );
              }, get('tags', isColor(x)))
            : [x];
          return [...mem, ...suggestion];
        },
        [],
        activeFilters
      )
    )
  );

  return (
    <Box
      fill
      align="center"
      justify="center"
      gap="medium"
      className="ugc-gallery-scroller"
    >
      <Heading level={1}>None of these yet</Heading>
      <Paragraph>But, you can still paint your space this color.</Paragraph>
      <Paragraph>Also, try one of these filters.</Paragraph>
      <Box direction="row-responsive" gap="small">
        {suggestions.map((x) => (
          <Button
            secondary
            onClick={() => handleSetFilter(get('slug', x) || x)}
            key={x.slug}
            label={`All ${(get('name', x)
              ? startCase(get('name', x))
              : x
            ).replace('color:', '')}`}
          />
        ))}
      </Box>
    </Box>
  );
};

UgcGalleryEmptyState.propTypes = {
  activeFilters: PropTypes.array,
  featuredFilters: PropTypes.array,
  colors: PropTypes.array,
  onResetFilters: PropTypes.func,
};

export default UgcGalleryEmptyState;
