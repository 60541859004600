import React from 'react';
// import { Blank } from 'grommet-icons';

const IconAccordion = () => (
  <svg
    width="21px"
    height="21px"
    viewBox="0 0 21 21"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.975 19.956C19.471 9.73 11.25 1.528 1 1.025v18.973l18.975-.042z"
      stroke="#000"
      strokeWidth="2"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
);

export default IconAccordion;
